import { InputWrapper, useMantineTheme } from "@mantine/core";
import { SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useEffect, useRef, useState } from "react";

import { usePlacesAutocompleteServiceResponse } from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useIntersection, useScrollIntoView } from "@mantine/hooks";
import classNames from "classnames";
import { useRouter } from "next/router";
import { CtaButton } from "@/components/common/ctaButton/CtaButton";
import {
  Coordinates,
  DisponibilitiesQueryParams,
  FormValues,
  Trips,
} from "@/interfaces/searchBlock";
import TrackingService from "@/utils/trackingService";
import { routes } from "@/routes/routes";
import HydratedMediaQuery from "@/utils/hydratedMediaQuery";
import { getBreakpoint } from "@/utils/mantine";
import { multiResultCityReferential } from "@/const/guaranteedCat";
import { PlacePredictionAutocomplete } from "./children/autocomplete/PlacePredictionAutocomplete";
// import { GoogleMapsAutocomplete } from "./children/autocomplete/GoogleMapsAutocomplete";
import {
  VehicleType,
  VehicleTypeSelection,
} from "./children/vehicleTypeSelection/VehicleTypeSelection";

import { computeDisponibilitiesQueryParams } from "./helpers/computeDisponibilitiesQueryParams";
import styles from "./SearchBlock.module.scss";
import { isFormValidAndDisplayErrors } from "./validation/isFormValidAndDisplayErrors";

import {
  oneWayInputs,
  roundTripInput,
} from "./children/autocomplete/context/autocompleteInputs";
import { SearchBlockCheckbox } from "./children/checkbox/SearchBlockCheckbox";
import { dateTimePickers } from "./children/dateTimePicker/context/dateTimePickerInputs";
import { getAutoFocusNextInput } from "./helpers/autoFocusNextInput";
import {
  isAnyAutocompleteFieldError,
  isAutocompleteFieldError,
} from "./validation/autocomplete/autocompleteValidationService";
import { CollapsedSearchBlock } from "./children/collapsedSearchBlock/CollapsedSearchBlock";
import DateTimePickersWithLazyLoading from "./children/dateTimePicker/DateTimePickersWithLazyLoading";
import { PlacePredictionService } from "./children/autocomplete/hooks/usePlacePrediction";
import GoogleMapsAutocomplete from "./children/autocomplete/GoogleMapsAutocomplete";

const showGooglePlacePrediction =
  process.env.NEXT_PUBLIC_SHOW_GOOGLE_PLACE_PREDICTION ?? "true";

type SearchBlockProps = {
  initialState?: FormValues;
  placesService?: google.maps.places.PlacesService | null;
  coordinates?: Coordinates;
  refreshSessionToken?: () => void;
  googleMapsPredictionService?: Pick<
    usePlacesAutocompleteServiceResponse,
    "placePredictions" | "getPlacePredictions" | "isPlacePredictionsLoading"
  >;
  placePredictionService: PlacePredictionService | null;
  loadGoogleMapsScript: () => void;
  autoTriggerResearch: boolean;
  shouldCollapseSearchBlock: "always" | "whenScrolled" | "never";
  lazyLoadDatePickers: boolean;
  setIsOneWayTrip?: (value: boolean) => void;
};

export const SearchBlock = ({
  initialState,
  placesService,
  coordinates,
  refreshSessionToken,
  googleMapsPredictionService,
  placePredictionService,
  loadGoogleMapsScript,
  autoTriggerResearch,
  shouldCollapseSearchBlock,
  lazyLoadDatePickers,
  setIsOneWayTrip,
}: SearchBlockProps) => {
  const intl = useIntl();
  const router = useRouter();
  const theme = useMantineTheme();
  const isDesktop = HydratedMediaQuery(getBreakpoint(theme.breakpoints.lg));

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    ...useFormPropsForValidation
  } = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      vehicleType: VehicleType.CAR,
      isOneWayTrip: false,
    },
  });

  const isOneWayTrip = watch("isOneWayTrip");
  const vehicleType = watch("vehicleType");

  useEffect(() => {
    if (setIsOneWayTrip !== undefined) {
      isOneWayTrip ? setIsOneWayTrip(true) : setIsOneWayTrip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOneWayTrip]);

  const [isRealDateTimePicker, setIsRealDateTimePicker] = useState(
    !lazyLoadDatePickers
  );

  const isAutocompleteFieldsError = isAnyAutocompleteFieldError({
    validationMethods: useFormPropsForValidation,
  });

  const displayAgencyPropositions =
    !isOneWayTrip && vehicleType !== VehicleType.CAR_WITHOUT_LICENCE;

  const identifyCityCountry = (inputString: string) => {
    const parts = inputString.split(", ").map((s) => s.trim().toUpperCase()); // Convert the parts to upper case

    let identifiedCity = "";
    let identifiedCountry = "";

    for (const ref of Array.from(multiResultCityReferential)) {
      const [cityRef, countryRef] = ref.split(", ").map((s) => s.trim());

      // City search
      if (!identifiedCity && parts.includes(cityRef)) {
        identifiedCity = cityRef;
      }

      // Country search
      if (!identifiedCountry && parts.includes(countryRef)) {
        identifiedCountry = countryRef;
      }
    }

    return `${identifiedCity}, ${identifiedCountry}`;
  };

  const computeQueryParamsAndSubmit: SubmitHandler<FormValues> = async (
    formValues
  ) => {
    if (!isFormValidAndDisplayErrors(formValues, useFormPropsForValidation)) {
      return;
    }

    if (placesService === undefined || placesService === null) {
      throw new Error(
        `Google autocomplete not defined. FormValues: ${JSON.stringify(
          formValues
        )}`
      );
    }

    if (refreshSessionToken === undefined) {
      throw new Error("Refresh session token not defined");
    }

    const beforeDisponibilitiesQueryParams = window.performance.now();
    let disponibilitiesQueryParams: DisponibilitiesQueryParams =
      await computeDisponibilitiesQueryParams(
        placePredictionService,
        placesService,
        refreshSessionToken,
        formValues,
        coordinates
      );
    const afterDisponibilitiesQueryParams = window.performance.now();
    TrackingService.trackUserTiming(
      "Call google Maps API",
      afterDisponibilitiesQueryParams - beforeDisponibilitiesQueryParams
    );

    if (
      disponibilitiesQueryParams["IsOnlyWithoutLicense"] ||
      disponibilitiesQueryParams["RideType"] === "OneWay" ||
      multiResultCityReferential.has(
        identifyCityCountry(
          disponibilitiesQueryParams.pickupDescription.toUpperCase()
        )
      )
    ) {
      TrackingService.trackUserSearch(disponibilitiesQueryParams);

      return router.push({
        pathname: routes.resultPage,
        query: disponibilitiesQueryParams,
      });
    } else {
      disponibilitiesQueryParams = {
        ...disponibilitiesQueryParams,
        "Pickup.MaxNumberOfAgencies": 1,
      };
      TrackingService.trackUserSearch(disponibilitiesQueryParams);

      return router.push({
        pathname: routes.resultGuaranteedCat,
        query: disponibilitiesQueryParams,
      });
    }
  };

  useEffect(() => {
    // We can't access queryParams on the first render, so we need to reset state afterwards
    reset(initialState);
  }, [initialState, reset]);

  const autocompleteInputs = isOneWayTrip ? oneWayInputs : roundTripInput;

  const startTripRef = useRef<HTMLInputElement>(null);
  const endTripRef = useRef<HTMLInputElement>(null);

  const startDateRef = useRef<HTMLInputElement>(null);
  const startTimeRef = useRef<HTMLInputElement>(null);

  const endDateRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);

  const formRef = useRef<HTMLFormElement>(null);

  const autoFocusNextInput = getAutoFocusNextInput({
    getValues: useFormPropsForValidation.getValues,
    endTripRef,
    startDateRef,
    startTimeRef,
    endDateRef,
    endTimeRef,
  });

  const [targetDetectionRef, observedEntry] = useIntersection({
    threshold: 0,
  });

  // Scroll action
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 5,
    duration: 400,
  });

  const isSearchBlockCollapsed =
    shouldCollapseSearchBlock === "always" ||
    (shouldCollapseSearchBlock === "whenScrolled" &&
      observedEntry?.isIntersecting === false);

  const loadLazyResources = () => {
    loadGoogleMapsScript();
    setIsRealDateTimePicker(true);
  };

  return (
    <>
      {isSearchBlockCollapsed && initialState !== undefined && (
        <CollapsedSearchBlock
          formValues={initialState}
          scrollToSearchBlock={() => {
            formRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
          alwaysCollapseSearchBlock={shouldCollapseSearchBlock === "always"}
        />
      )}

      <form
        onSubmit={handleSubmit(computeQueryParamsAndSubmit)}
        onClick={loadLazyResources}
        onPointerEnter={loadLazyResources}
        className={classNames({
          [styles.hidden]: isSearchBlockCollapsed,
        })}
        ref={formRef}
      >
        <div className={styles.searchBlockContainer} ref={targetDetectionRef}>
          <div className={styles.searchBlockContent}>
            <div className={styles.vehicleTypeContainer}>
              <VehicleTypeSelection
                control={control}
                setValue={setValue}
                getValues={useFormPropsForValidation.getValues}
                formRef={formRef}
                autoTriggerResearch={autoTriggerResearch}
                isOneWayTrip={isOneWayTrip}
              />
            </div>

            <div className={styles.checkboxContainer}>
              <SearchBlockCheckbox
                control={control}
                validationMethods={useFormPropsForValidation}
                disabled={vehicleType === VehicleType.CAR_WITHOUT_LICENCE}
                setValue={setValue}
              />
            </div>

            <div className={styles.container}>
              <InputWrapper
                className={styles.roundTripContainer}
                error={
                  isAutocompleteFieldsError
                    ? intl.formatMessage({
                        id: "searchBlockAutocompleteError",
                      })
                    : null
                }
              >
                <div
                  className={styles.roundTripSubContainer}
                  ref={targetRef}
                  onFocus={() => {
                    if (!isDesktop) {
                      scrollIntoView();
                    }
                  }}
                >
                  {showGooglePlacePrediction === "true"
                    ? autocompleteInputs.map((trip) => (
                        <GoogleMapsAutocomplete
                          key={trip.inputId}
                          control={control}
                          name={trip.name}
                          icon={trip.icon(
                            isAutocompleteFieldError({
                              fieldName: trip.name,
                              validationMethods: useFormPropsForValidation,
                            })
                          )}
                          inputId={trip.inputId}
                          ariaLabel={intl.formatMessage({
                            id: trip.ariaLabel,
                          })}
                          placeholder={intl.formatMessage({
                            id: trip.placeholder,
                          })}
                          thinBorder={trip.thinBorder}
                          googleMapsPredictionService={
                            googleMapsPredictionService
                          }
                          validationMethods={useFormPropsForValidation}
                          autoFocusNextInput={autoFocusNextInput}
                          tripRef={
                            trip.name === Trips.ONE_WAY_END
                              ? endTripRef
                              : startTripRef
                          }
                          displayAgencyPropositions={displayAgencyPropositions}
                        />
                      ))
                    : autocompleteInputs.map((trip) => (
                        <PlacePredictionAutocomplete
                          key={trip.inputId}
                          control={control}
                          name={trip.name}
                          icon={trip.icon(
                            isAutocompleteFieldError({
                              fieldName: trip.name,
                              validationMethods: useFormPropsForValidation,
                            })
                          )}
                          inputId={trip.inputId}
                          ariaLabel={intl.formatMessage({
                            id: trip.ariaLabel,
                          })}
                          placeholder={intl.formatMessage({
                            id: trip.placeholder,
                          })}
                          thinBorder={trip.thinBorder}
                          placePredictionService={placePredictionService}
                          validationMethods={useFormPropsForValidation}
                          autoFocusNextInput={autoFocusNextInput}
                          tripRef={
                            trip.name === Trips.ONE_WAY_END
                              ? endTripRef
                              : startTripRef
                          }
                          displayAgencyPropositions={displayAgencyPropositions}
                        />
                      ))}
                </div>
              </InputWrapper>

              <div className={styles.dateTimePickersContainer}>
                <DateTimePickersWithLazyLoading
                  dateTimePickers={dateTimePickers}
                  isRealDateTimePicker={isRealDateTimePicker}
                  control={control}
                  setValue={setValue}
                  validationMethods={useFormPropsForValidation}
                  autoFocusNextInput={autoFocusNextInput}
                  startDateRef={startDateRef}
                  endDateRef={endDateRef}
                  startTimeRef={startTimeRef}
                  endTimeRef={endTimeRef}
                />

                <div className={styles.searchButtonContainer}>
                  <CtaButton
                    label={intl.formatMessage({
                      id: "searchBlockCta",
                    })}
                    dataCy="searchBlockCta"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
