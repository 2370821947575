export type GuaranteedCategory = {
  title: string;
  subtitle: string;
  categoryCode: string;
};

export const multiResultCityReferential = new Set([
  "PARIS, FRANCE",
  "MARSEILLE, FRANCE",
  "MONTPELLIER, FRANCE",
  "LYON, FRANCE",
  "GRENOBLE, FRANCE",
  "NICE, FRANCE",
  "PERPIGNAN, FRANCE",
  "TOULOUSE, FRANCE",
  "BÉZIERS, FRANCE",
  "CANNES, FRANCE",
  "LILLE, FRANCE",
  "STRASBOURG, FRANCE",
  "AUBERGENVILLE, FRANCE",
  "SAINT-DENIS, FRANCE",
  "AVIGNON, FRANCE",
  "BORDEAUX, FRANCE",
  "BOURGES, FRANCE",
  "BREST, FRANCE",
  "CLERMONT-FERRAND, FRANCE",
  "DIJON, FRANCE",
  "MAUGUIO, FRANCE",
  "MONTÉLIMAR, FRANCE",
  "MULHOUSE, FRANCE",
  "NANTES, FRANCE",
  "NÎMES, FRANCE",
  "NIORT, FRANCE",
  "RENNES, FRANCE",
  "TOULON, FRANCE",
  "TOURS, FRANCE",
  "BASTIA, FRANCE",
]);
